import React from 'react';
import Sigma from "sigma";
import { instance } from '../getInstanceAxios.js';
import Graph from "graphology";
import circular from "graphology-layout/circular";
import forceAtlas2 from "graphology-layout-forceatlas2";
import { cropToLargestConnectedComponent } from "graphology-components";
import Spinner from '../../components/Spinner/Spinner'

const COLORS = { actor: "#1d3fcd", Economia: "#fff450", Politica: "#fdb94d", Estrategia: "#f04e4d" };

const MapModal = (props) => {

  const [loading, setLoading] = React.useState(true);  
  const myRef = React.createRef();

  React.useEffect(() => {
    const filter = props.data;
    console.log(filter);

    const graph = new Graph();

    instance.get('notice?'+filter).then(res => {
      console.log('res', res);
      const {actors, notices, relations} = res.data;

      // Create the actors nodes:
      actors.forEach(actor => {
        if (!graph.hasNode(actor.id)) 
          graph.addNode(actor.id, {
            nodeType: "actor",
            label: actor.name,
          });
      });

      // For each notice, create the node if it does not exist yet:
      notices.forEach(notice => {
        if (!graph.hasNode(notice.id)) 
          graph.addNode(notice.id, { nodeType: notice.orbit, label: notice.title });
      });

      relations.forEach(relation => {
        if (!graph.hasEdge(relation.actor_id, relation.notice_id)) {
          try {
            graph.addEdge(relation.actor_id, relation.notice_id, { weight: 1, color: COLORS[notices.find(el => el.id === relation.notice_id).orbit] });
          } catch(err) {
            console.log('error con el actor '+relation.actor_id);
          }
        } 
      });


      // 3. Only keep the main connected component:
      cropToLargestConnectedComponent(graph);

      // 4. Add colors to the nodes, based on node types:
      graph.forEachNode((node, attributes) =>
        graph.setNodeAttribute(node, "color", COLORS[attributes.nodeType]),
      );

      // 5. Use degrees for node sizes:
      const degrees = graph.nodes().map((node) => graph.degree(node));
      const minDegree = Math.min(...degrees);
      const maxDegree = Math.max(...degrees);
      const minSize = 2,
        maxSize = 15;
      graph.forEachNode((node) => {
        const degree = graph.degree(node);
        graph.setNodeAttribute(
          node,
          "size",
          minSize + ((degree - minDegree) / (maxDegree - minDegree)) * (maxSize - minSize),
        );
      });

      // 6. Position nodes on a circle, then run Force Atlas 2 for a while to get
      //    proper graph layout:
      circular.assign(graph);
      const settings = forceAtlas2.inferSettings(graph);
      forceAtlas2.assign(graph, { settings, iterations: 600 });

      // 7. Hide the loader from the DOM:
      // const loader = document.getElementById("loader") as HTMLElement;
      // loader.style.display = "none";

      new Sigma(graph, myRef.current);
      // setLoading(false);
    })
    .catch(err => console.log(err));
  }, []);

  return <div style={{height: '500px'}} ref={myRef}>
    {/* {loading && <Spinner />} */}
  </div>;
};

export default MapModal;