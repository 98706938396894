import React from 'react';
import { Switch, FormControlLabel, Fab, AddIcon, Divider, Button, DialogContent,DialogTitle }
    from '../materialUi.js';
import TextField from "../FormikTextField";
import * as Yup from 'yup';
import { Field, withFormik } from 'formik';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import useStyles from "../styleGrid"
import AddTagToNotice from './AddTagToNotice.js';

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
    }),

    mapPropsToValues: props => ({
        getNotices: props.getNotices,
        title: '',
    }),

    handleSubmit: (values, { setSubmitting }) => {
        let filter="";

        if ( values.since )
            filter += 'since='+values.since+'&';
        if ( values.until )
            filter += 'until='+values.until+'&';

        if ( values.tags ) values.tags.forEach( tag => filter += 'tags[]='+tag.id+'&' );
        if ( values.actors ) values.actors.forEach( actor => filter += 'actors[]='+actor.id+'&' );
        if ( values.orbits ) values.orbits.forEach( orbit => filter += 'orbits[]='+orbit.value+'&' );
        if ( values.ambits ) values.ambits.forEach( ambit => filter += 'ambits[]='+ambit.value+'&' );
        if ( values.sectors ) values.sectors.forEach( sector => filter += 'sectors[]='+sector.value+'&' );
        if ( values.sources ) values.sources.forEach( source => filter += 'sources[]='+source.id+'&' );
        if ( values.types ) values.types.forEach( type => filter += 'types[]='+type.value+'&' );
        if ( values.title ) { filter += 'title='+values.title+'&' };

        setTimeout(() => {
            values.getNotices( 1, filter );
            setSubmitting(false);
        }, 1000);
    },

    displayName: 'MyForm',
});


const MyForm = props => {
    const classes = useStyles();
    const {
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        handleClose,
        handleSubmit,
        handleMap,
        handleChangeSwitch,
        handleChangeSwitchDate,
        orbits,
        sectors,
        ambits,
        sources,
        types,
        actors,
        tags,
        conjunction,
        orderDate,
        getActors,
        getTags,
        canReadTag
         } = props;

    let newOrbits = orbits.map( orbit => ( { id: orbit, value: orbit, label: orbit } ) );
    let newSources = sources.map( source => ( { id: source.id, value: source.name, label: source.name } ) );
    let newActors = actors.map( actor => ( { id: actor.id, value: actor.name, label: actor.name } ) );
    let newAmbits = ambits.map( ambit => ( { id: ambit, value: ambit, label: ambit } ) );
    let newTypes = types.map( type => ( { id: type, value: type, label: type } ) );
    let newSectors = sectors.map( sector => ( { id: sector, value: sector, label: sector } ) );
    let newTags = tags.map( tag => ( { id: tag.id, value: tag.name, label: tag.name } ) );

    return <div>
                <form onSubmit={handleSubmit}>
                    <DialogTitle style={{marginLeft: "15px"}} id="form-dialog-title">Filtrar</DialogTitle>
                    <Divider />
                    <DialogContent>
                    <div className={classes.root}>
                        <Grid container xs={12}>
                            <Grid item xs={2}>
                                <Field
                                    type="date"
                                    name="since"
                                    label="Desde"
                                    component={TextField}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Field
                                    type="date"
                                    name="until"
                                    label="Hasta"
                                    component={TextField}
                                    InputLabelProps={{ shrink: true }}

                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    label="La última noticia aparece primero"
                                    style={{marginTop:"20px"}}
                                    control={
                                        <Switch
                                            onChange={() => handleChangeSwitchDate(orderDate)}
                                            value={values.orderDate}

                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Field
                                    type="title"
                                    name="title"
                                    label="Titulo"
                                    value={values.title}
                                    component={TextField}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <MySelect
                                    newItems={newSources}
                                    title="Fuentes"
                                    name="sources"
                                    value={values.sources}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.sources}
                                    touched={touched.sources}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <MySelect
                                    newItems={newTypes}
                                    title="Tipos"
                                    name="types"
                                    value={values.types}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.types}
                                    touched={touched.types}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <MySelect
                                    newItems={newAmbits}
                                    title="Ambitos"
                                    name="ambits"
                                    value={values.ambits}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.ambits}
                                    touched={touched.ambits}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <MySelect
                                    newItems={newOrbits}
                                    title="Orbitas"
                                    name="orbits"
                                    value={values.orbits}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.orbits}
                                    touched={touched.orbits}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <MySelect
                                    newItems={newSectors}
                                    title="Sectores"
                                    name="sectors"
                                    value={values.sectors}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors.sectors}
                                    touched={touched.sectors}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <MySelectActors
                                    newItems={newActors}
                                    title="Actores"
                                    name="actors"
                                    value={values.actors}
                                    onChange={setFieldValue}
                                    getActors={getActors}
                                    onBlur={setFieldTouched}
                                    error={errors.actors}
                                    touched={touched.actors}
                                    handleChangeSwitch={handleChangeSwitch}
                                    conjunction={conjunction}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {canReadTag && <SelectTags
                                    newItems={newTags}
                                    title="Tags"
                                    name="tags"
                                    value={values.tags}
                                    onChange={setFieldValue}
                                    getTags={getTags}
                                    onBlur={setFieldTouched}
                                    error={errors.tags}
                                    touched={touched.tags}
                                    handleChangeSwitch={handleChangeSwitch}
                                />}
                            </Grid>
                        </Grid>
                            <div className="row" >
                                <Button variant="contained" type="submit" color="primary" style={{ margin:"10px", marginLeft:"28px", color:'#ffff', backgroundColor:"#605A73"}}>
                                    Filtrar
                                </Button>
                                <Button variant="contained" onClick={() => handleMap(props.values)} color="primary" style={{ margin:"10px", color:'#ffff', backgroundColor:"#605A53"}}>
                                    Mapear
                                </Button>
                                <Button variant="contained" onClick={handleClose} style={{ margin:"10px"}}>
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </form>
        </div>;
}


class MySelectActors extends React.Component {

    onInputChange = input => {
        this.props.getActors( input );
    }

    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(this.props.name, value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur(this.props.name, true);
    };


    render() {
        return (
            <div style={{ margin: '1rem 0'}}>
                { this.props.title === "Actores" ?
                    <Grid container xs={12}>
                        <Grid item xs={3}>
                            <label htmlFor="color">{this.props.title}</label>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControlLabel
                                label="Que aparezcan juntos"
                                style={{marginTop:"-28px"}}
                                control={
                                    <Switch
                                        onChange={() => this.props.handleChangeSwitch(this.props.conjunction)}
                                        value={ this.props.conjunction }
                                    />
                                }
                            />
                        </Grid>
                    </Grid> :
                    <Grid item xs={6}>
                        <label htmlFor="color">{this.props.title}</label>
                    </Grid>
                }
                <Select
                    maxMenuHeight={90}
                    id="color"
                    options={this.props.newItems}
                    isMulti={true}
                    onChange={this.handleChange}
                    onInputChange={this.props.getActors ? this.onInputChange : null}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                    placeholder={"Puede ingresar multiples " + this.props.title}
                />
                {!!this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                )}
            </div>
        );
    }
}

class MySelect extends React.Component {

    onInputChange = input => {
        this.props.getTags( input );
    }

    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(this.props.name, value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur(this.props.name, true);
    };


    render() {
        return (
            <div style={{ margin: '1rem 0'}}>
                <div className="row" style={{marginLeft: "2px"}}>
                    <div>
                        <label htmlFor="color">{this.props.title}</label>
                    </div>
                </div>

                <Select
                    maxMenuHeight={90}
                    id="color"
                    options={this.props.newItems}
                    isMulti={true}
                    onChange={this.handleChange}
                    onInputChange={this.props.getTags ? this.onInputChange : null}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                    placeholder={"Puede ingresar multiples " + this.props.title}
                />
                {!!this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                )}
            </div>
        );
    }
}

class SelectTags extends React.Component {

    state={
        openTreeTag:false,
    }


    handleAddTag = values => {
        this.props.onChange( 'tags', values );
        this.setState({ openTreeTag:false });
    };


    handleCloseTag = () => {
        this.setState({
            openTreeTag:false,
        });
    }

    onInputChange = input => {
        this.props.getTags( input );
    }

    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(this.props.name, value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur(this.props.name, true);
    };


    render() {
        return (
            <div style={{ margin: '1rem 0'}}>
                <Grid container style={{ width:"100%" }} xs={12}>
                    <Grid item xs={1}>
                        <Fab style={{ marginTop:"25px", marginLeft:"15px", backgroundColor: '#605a73' }}
                            size="small"
                            color='secondary'
                            title="Agregar tags"
                            onClick={() => this.setState({ openTreeTag:true })}>
                            <AddIcon />
                        </Fab>
                    </Grid>
                    <Grid container style={{ width:"100%" }} xs={11}>
                        <Grid item xs={11}>
                                <label htmlFor="color">{this.props.title}</label>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                id="color"
                                isMulti={true}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                value={this.props.value}
                                placeholder="Solo puede eliminar tags"
                                isClearable={true}
                                isOptionDisabled={false}
                                skipDisabled={true}
                                closeOnSelect={false}
                                removeSelected={false}
                            />
                        </Grid>
                        {this.state.openTreeTag &&
                            <AddTagToNotice
                                handleCloseTag={this.handleCloseTag}
                                updateTags={this.handleAddTag}
                            />
                        }
                    </Grid>
                </Grid>

                {!!this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                )}
            </div>
        );
    }
}



const FilterModal = formikEnhancer(MyForm);
export default FilterModal;
