import React, { Component } from 'react';
import Table from "../../components/Table/Table";
import { instance } from '../getInstanceAxios.js';
import { handleAllCheckBoxGeneric, handleCheckBoxGeneric, handleChangePageGeneric } from '../TableFunctions.js';
import AddModal  from './AddModal';
import FilterModal  from './FilterModal';
import MapModal  from './MapModal';
import { tagsToTableConvert }  from './functionsTags';

class Notice extends Component {

    state = {
        data: [],
        pagination: {},
        error: null,
        isLoaded: true,
        orbits: [ "Economia", "Politica", "Estrategia" ],
        sectors: [ "Politica", "Agro", "Energia", "Finanzas", "Trabajadores", "Industria" ],
        ambits: [ "Nacional", "Latinoamerica", "Internacional" ],
        types: [ "Nota", "Opinion" ],
        sources:[],
        actors:[],
        tags:[],
        link:[],
        openAdd: null,
        openUpdate:null,
        openTagMany:null,
        content: null,
        errorModal: null,
        noticeData: null,
        noticeLoaded: false,
        openFilter:false,
        dataMap: "",
        viewTable: false,
        conjunction: false,
        orderDate: false,
        msgFilter: "El filtro es: ",
        selected: [],
        selectedTags:[],
        selectAll: false,
    }

    FILTER = "";

    componentDidMount(){
        this.getUserSources();
    }

    handleChange = event => {
        this.setState({
            [ event.target.id ]: event.target.value
        });
    }

    handleChangeSwitch = () => {
        this.setState({conjunction:!this.state.conjunction})
    }

    handleChangeSwitchDate = () => {
        this.setState({orderDate:!this.state.orderDate})
    }

    handleClose = () => {
        this.setState({
            sources:[],
            errorModal:null,
            selected:[],
            openUpdate: false,
            openAdd: false,
            openFilter: false,
            noticeLoaded:false,
            openTagMany:false,
            dataMap: "",
        });
    }

    handleCloseTag = () => {
        this.setState({
            openTagMany:false,
        });
    }

    /**
        Metodo encargado de actualizar la noticia en la bd
     **/
    handleSubmitUpdate = ( event ) => {
        const noticeData = {
            web_link: event.links,
            date: (event.date + " 00:00:00"),
            title: event.title,
            content: event.content,
            orbit: event.orbit,
            ambit: event.ambit,
            type: event.type,
            sector: event.sector,
            source_id: event.source,
            actors: event.topics,
        };

        const path = 'notice/'+ this.state.selected[0];
        instance.put(path, noticeData)
            .then( res => {
                this.setState({
                    errorModal:"Registro modificado correctamente",
                    noticeLoaded:false,
                    actors:[],
                    viewTable:false,
                    openFilter:false,
                    openUpdate:false,
                    selected: [],
                    selectAll: false,
                });

            })
            .catch( err => {
                if ( err.response.status === 400 ) {
                    err = "El campo link no debe tener mas de 2000 caracteres"
                }
                this.setState({ errorModal:err });
        });
    }


    /**
        Metodo encargado de crear la noticia en la bd
     **/
    handleSubmitAdd = ( event ) => {
        const noticeData = {
            web_link: event.links,
            date: (event.date + " 00:00:00"),
            title: event.title,
            content: event.content,
            orbit: event.orbit,
            type: event.type,
            ambit: event.ambit,
            sector: event.sector,
            source_id: event.source,
            actors: event.topics,
        };
        instance.post('notice/', noticeData)
            .then( res => {
                this.setState({
                    errorModal:"Registro creado correctamente",
                    openAdd:null,
                    actors:[],
                    viewTable:false,
                    openFilter:false,
                    selected: [],
                    selectAll: false,
                });
            })
            .catch( err => {
                const errorNumber = err.response.status;
                if ( errorNumber === 400 ) {
                    err = "Algun campo exedio los 2000 caracteres"
                }
                if ( errorNumber === 403 ) {
                    err = "No tiene permiso para utilizar esta fuente"
                }
                this.setState({ errorModal:err });
        });
    }

    /**
        Metodo a cargo de traer las noticias
      **/
    getNotices = ( page=1, filter="" ) => {
        let url = 'notice?page='+page;
        if ( filter || this.state.orderDate || this.state.conjunction ) {
            filter += 'orderDate='+this.state.orderDate + '&';
            filter += 'conjunction='+this.state.conjunction+'&';
            url += '&'+filter;
            //para el export guardo el filter
            this.FILTER = filter;
        } else {
            url += '&'+this.FILTER;
        }

        this.setState({ isLoaded:false, viewTable:true, openFilter:false }, () => {
            instance.get( url )
            .then( res => {
                const { total, current_page, per_page, data } = res.data.notices;
                var genericData = data.map( item => {
                    return { idNotice:item.id, first: item.id, second: item.date, third: item.title };
                });
                this.setState({
                    orderDate:false,
                    conjunction:false,
                    data:genericData,
                    pagination: { total, currentPage: current_page, rowsPerPage: per_page },
                    isLoaded:true,
                    msgFilter:res.data.msgFilter,
                });
            })
            .catch( err => {
                this.setState({ error:err, isLoaded:true });
            });
        });
    }

    /**
        Metodo a cargo de traer las actores
    **/
    getActors = ( filter ) => {
        if ( filter && filter.length >= 2 ) {
            instance.get( 'actor?filter='+filter )
            .then( res => {
                this.setState({ actors:res.data , isLoaded:true });
            })
            .catch( err => {
                this.setState({ error:err, isLoaded:true });
            });
        } else {
            this.setState({ actors:[] , isLoaded:true });
        }
    }

    /**
    Metodo a cargo de traer los tags
    **/
    getTags = ( filter ) => {
        if ( filter && filter.length >= 2 ) {
            instance.get( 'tag?filter='+filter )
            .then( res => {
                this.setState({
                    tags: res.data,
                    isLoaded:true
                });
            })
            .catch( err => {
                this.setState({ error:err, isLoaded:true });
            });
        } else {
            this.setState({ tags:[] , isLoaded:true });
        }
    }

    /**
        Metodo a cargo de traer las noticias
    **/
    getSources = () => {
        instance.get('source/')
        .then( res => {
            const data = res.data;
            this.setState({ sources:data , isLoaded:true });
        })
        .catch( err => {
            this.setState({ error:err, isLoaded:true });
        });
    }

    getUserSources = () => {
        instance.get('user/'+this.props.userId+'/source')
        .then( res => {
            const data = res.data;
            this.setState({ sources:data , isLoaded:true });
        })
        .catch( err => {
            this.setState({ error:err, isLoaded:true });
        });
    }


    /**
        Metodo encargado de setear la fecha actual
     **/
    getCurrentDate = (separator='') => {
        let newDate = new Date();
        let date = newDate.getDate();
        if ( date < 10 ) {
            date = "0" + date;
        }
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }

    /**
    Metodo encargado de actualizar las noticias seleccionadas con los tags indicados
    **/
    handleTag = ( tagIdParams ) => {
        let tagData = {};
        let filt = "";

        tagData = {
            newTags: tagIdParams,
            notices: this.state.selected,
            selectAll: this.state.selectAll
        };

        if ( this.FILTER )
            filt = '?filter=true&'+this.FILTER;

        this.setState({ isLoaded:false }, () => {
            instance.post('notice/updateTags' + filt, tagData )
            .then( res => {
                this.setState({
                    errorModal:"Tags asignados correctamente",
                    openTagMany:false,
                    tags:[],
                    selected:[],
                    selectAll:false,
                    isLoaded:true,
                    openUpdate:false,
                });
            })
            .catch( err => {
                const errorNumber = err.response.status;
                if ( errorNumber === 400 ) {
                    err = "No hay tags que vincular"
                }
                this.setState({
                    errorModal:err,
                    openUpdate:false,
                    selectAll:false,
                    openTagMany:false,
                    noticeLoaded:false,
                    tags:[],
                    isLoaded:true,
                    selected:[],
                 });
            });
        });
    }

    /**
    Metodo encargado de activar el modal de Agregar
    **/
    onAddItem = () => {
        this.setState({ openAdd: true, errorModal: null });
    }

    /**
    Metodo encargado de eliminar un elemento o muchos en el backend
    **/
    onDelete = ( idArray ) => {
        for (var i = 0; i < idArray.length; i++) {
            instance.delete('notice/'+ idArray[i])
            .then( res => {
                this.setState({ selected: [], selectAll: false, errorModal:"Registro/s eliminado/s correctamente" });
                this.getNotices();
            })
            .catch( err => {
                process.env.NODE_ENV !== 'production' && console.log( err );
            });
        }
    }

    onUpdate = ( idArray ) => {
        this.setState({ openUpdate:false }, () => {
            instance.get( '/notice/'+ idArray[0] ).then( res => {
                let actors = res.data.actors.map( actor => ({ id: actor.id , value: actor.name, label: actor.name }) );
                let tags = res.data.tags.map( tag => ({ id: tag.id , value: tag.name, label: tag.name }) );
                const noticeData = {
                    orbit: res.data.orbit,
                    sector: res.data.sector,
                    ambit: res.data.ambit,
                    date: res.data.date,
                    content: res.data.content,
                    type: res.data.type,
                    source_id: res.data.source_id,
                    idNotice: res.data.id,
                    title: res.data.title,
                    links: res.data.web_link,
                    actors: actors,
                    tags:tags,
                }

                this.setState({ noticeLoaded: true,
                    noticeData: noticeData,
                    isLoaded:true,
                    errorModal: null,
                    openUpdate: true,
                    selected: [idArray[0]],
                    openTagMany:false,
                });
            }).catch( err => {
                this.setState({ error:err, isLoaded:true });
            });
        });
    }

    /**
        Metodo encargado de abrir el filter
     **/
    onFilter = () => {
        const openFilter = !this.state.openFilter;
        if ( openFilter ) {
            this.FILTER = "";
            this.setState({ openTagMany:false, dataMap: "", openUpdate:false, openFilter: openFilter, viewTable: false });
        } else {
            this.setState({ openFilter: openFilter, dataMap: "" });
        }
    }

    /**
        Metodo encargado de exportar un elemento o muchos en el backend
     **/
    onExportTxt = ( idArray=[] ) => {
        var fileDownload = require( 'js-file-download' );
        let url = 'notice/exportTxt';
        if ( idArray.length === 0 ) {
            if ( this.FILTER )
                url += '?'+this.FILTER;
        } else {
            url += '?';
            idArray.forEach( id => url += 'idArray[]='+id+'&' );
        }

        instance.get( url ).then( res => {
            fileDownload( res.data.content, res.data.name );
        }).catch( err => {
            this.setState({ error:err });
        });
    }

    /**
    Metodo encargado de abrir el taguear
    **/
    onTagMany = ( idArray, openTag ) => {
        this.getTagsForNotices();
        this.setState({ openTagMany:openTag, openUpdate:false })
    }

    getTagsForNotices = ( ) => {
        instance.get('tag')
        .then( res => {
            const data = tagsToTableConvert( res.data );
            this.setState({ tags:data });
        })
        .catch( err => {
            this.setState({ error:err });
        });
    }


    handleAllCheckBox = event => {
        let result = handleAllCheckBoxGeneric( event, this.state.data );
        this.setState({ selectAll:result.selectAll, selected:result.selected })
    };

    handleCheckBox = ( event, id ) => {
        let result = handleCheckBoxGeneric( event, id, this.state.selected );
        this.setState({ selectAll: false, selected: result.selected, openUpdate:false }, () => {
            if ( this.props.canTag )
                this.onTagMany( [], false );
        });
    };

    handleChangePage = (event, page) => {
        handleChangePageGeneric(event, page, this.getNotices )
    };


    handleMap = values => {
        this.handleClose();
                let filter="";

        if ( values.since )
            filter += 'since='+values.since+'&';
        if ( values.until )
            filter += 'until='+values.until+'&';

        if ( values.tags ) values.tags.forEach( tag => filter += 'tags[]='+tag.id+'&' );
        if ( values.actors ) values.actors.forEach( actor => filter += 'actors[]='+actor.id+'&' );
        if ( values.orbits ) values.orbits.forEach( orbit => filter += 'orbits[]='+orbit.value+'&' );
        if ( values.ambits ) values.ambits.forEach( ambit => filter += 'ambits[]='+ambit.value+'&' );
        if ( values.sectors ) values.sectors.forEach( sector => filter += 'sectors[]='+sector.value+'&' );
        if ( values.sources ) values.sources.forEach( source => filter += 'sources[]='+source.id+'&' );
        if ( values.types ) values.types.forEach( type => filter += 'types[]='+type.value+'&' );
        if ( values.title ) { filter += 'title='+values.title+'&' };
        if ( this.state.orderDate ) { filter += 'orderDate='+this.state.orderDate+'&' };
        if ( this.state.conjunction ) { filter += 'conjunction='+this.state.conjunction+'&' };
        filter += 'csv=true';
        this.setState({ dataMap: filter});
    }; 

    isSelected = id => this.state.selected.indexOf( id ) !== -1;

    render () {
        const title = "Registros";
        const roles = this.props.roles;
        const rows = [{ id: '1', numeric: false, paddingRight:"0px", label: '' },
                      { id: 'fecha', numeric: false, paddingRight:"0px", label: 'Fecha' },
                      { id: 'title', numeric: false, paddingRight:"0px", label: 'Titulo' }
                    ];
        if(this.state.error){
            return <div><h1>{this.state.error.message}</h1></div>
        } else {
            return <div>
                    <Table
                        title={title}
                        rows={rows}
                        data={this.state.data}
                        viewTable={this.state.viewTable}
                        isLoaded={this.state.isLoaded}
                        pagination={this.state.pagination}
                        onChangePage={this.getNotices}
                        onDelete={this.onDelete}
                        onAddItem={this.onAddItem}
                        onUpdate={this.onUpdate}
                        onFilter={this.onFilter}
                        onTagMany={this.onTagMany}
                        onExportTxt={this.onExportTxt}
                        errorModal={this.state.errorModal}
                        canReadAll={roles.includes('notice.read.all')}
                        canCreate={roles.includes('notice.create')}
                        canDelete={roles.includes('notice.delete')}
                        canExport={roles.includes('notice.export')}
                        canTag={roles.includes('notice.tag')}
                        readOnly={roles.includes('notice.read.all')}
                        handleAllCheckBox={this.handleAllCheckBox}
                        handleCheckBox={this.handleCheckBox}
                        handleChangePage={this.handleChangePage}
                        isSelected={this.isSelected}
                        selected={this.state.selected}
                        selectAll={this.state.selectAll}
                        openUpdate={this.state.openUpdate}
                        noticeData={this.state.noticeData}
                        handleChange={this.handleChange}
                        handleClose={this.handleClose}
                        handleSubmitUpdate={this.handleSubmitUpdate}
                        orbits={this.state.orbits}
                        sectors={this.state.sectors}
                        types={this.state.types}
                        ambits={this.state.ambits}
                        actors={this.state.actors}
                        sources={this.state.sources}
                        getActors={this.getActors}
                        messageFilter={this.state.msgFilter}
                        openTagMany={this.state.openTagMany}
                        handleTag={this.handleTag}
                        handleCloseTag={this.handleCloseTag}
                        tags={this.state.tags}
                        getTags={this.getTags}
                    />
                {this.state.openAdd &&
                    <AddModal
                        handleChange={this.handleChange}
                        handleClose={this.handleClose}
                        handleSubmitAdd={this.handleSubmitAdd}
                        errorModal={this.state.errorModal}
                        orbits={this.state.orbits}
                        sectors={this.state.sectors}
                        types={this.state.types}
                        ambits={this.state.ambits}
                        sources={this.state.sources}
                        actors={this.state.actors}
                        getActors={this.getActors}
                        dateNow={this.getCurrentDate("-")}
                    />
                }
                {this.state.openFilter &&
                    <FilterModal
                        orderDate={this.state.orderDate}
                        conjunction={this.state.conjunction}
                        getNotices={this.getNotices}
                        handleClose={this.handleClose}
                        handleMap={this.handleMap}
                        handleChangeSwitch={this.handleChangeSwitch}
                        handleChangeSwitchDate={this.handleChangeSwitchDate}
                        orbits={this.state.orbits}
                        sectors={this.state.sectors}
                        ambits={this.state.ambits}
                        types={this.state.types}
                        sources={this.state.sources}
                        actors={this.state.actors}
                        tags={this.state.tags}
                        getActors={this.getActors}
                        getTags={this.getTags}
                        canReadTag={roles.includes('tag.read')}
                    />
                }
                {this.state.dataMap &&
                    <MapModal
                        data={this.state.dataMap}
                    />
                }
            </div>
        }
    }
}

export default Notice;
